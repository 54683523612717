<template>
  <div class="csn-panel-group">
    <div class="panel-group">
      <div
        v-for="index in stepNumber"
        :key="index"
        class="panel panel-default csn-panel"
      >
        <div class="panel-heading">
          <span :class="getStepHeaderClass(index)">
            {{ index }}
          </span>
          <span class="panel-title">{{ getTitle(index) }}</span>
          <button
            v-if="hasBackButton(index)"
            @click="handleStepHeaderClick(index)"
            class="btn panel-back"
          >
            {{ t('back') }}
          </button>
        </div>

        <div class="panel-collapse collapse" :class="getStepBodyClass(index)">
          <div class="panel-body">
            <slot :name="`step-${index}`"></slot>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { TAB_PANELS } from '@/constants'
import { isNil, callFn, isEmptyOrNil } from '@/helpers'

/**
 * @param {array}  backButtonList sets back button on tab header
 * [null, 1, null, 1] array length equals number of steps.
 * "null" means there is no "back" button.
 * 1 means clicking on "back" button returns to step 1.
 */

export default {
  name: TAB_PANELS,
  data() {
    return {
      step: '1',
    }
  },
  props: {
    initialStep: [String, Number],
    blockedStep: {
      type: [String, Number, null],
      default: '',
    },
    hiddenStep: {
      type: [String, Number, null],
      default: '',
    },
    stepNumber: {
      type: [String, Number, null],
      default: null,
    },
    reset: Function,
    titleList: {
      type: Array,
      default: () => [],
    },
    backButtonList: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    nextStep() {
      return Number(this.step) + 1
    },
    isNextStepBlockedOrHidden() {
      return this.isBlocked(this.nextStep) || this.isHidden(this.nextStep)
    },
    isEnd() {
      return !isNil(this.stepNumber) && this.nextStep >= this.stepNumber
    },
    t() {
      return this.$createComponentTranslator(TAB_PANELS)
    },
  },
  watch: {
    initialStep: {
      immediate: true,
      handler(initialStep) {
        this.step !== initialStep && (this.step = initialStep)
      },
    },
  },
  methods: {
    isBlocked(step) {
      return !isEmptyOrNil(this.blockedStep) && step >= this.blockedStep
    },
    isHidden(step) {
      return !isEmptyOrNil(this.hiddenStep) && step >= this.hiddenStep
    },
    getPanelClass(index) {
      return [
        'panel panel-default csn-panel',
        index >= this.hiddenStep ? 'hidden' : '',
      ]
    },
    getTitle(index) {
      const step = index - 1

      return this.titleList[step] || ''
    },
    getStepHeaderClass(index) {
      return ['step', index === this.step ? 'active' : '']
    },
    handleStepHeaderClick(step) {
      const index = step - 1
      const nextStep = this.backButtonList[index]

      this.step = nextStep
      this.$emit(`on-click-header-'${nextStep}`)
      nextStep === 1 && this.reset()
    },
    getStepBodyClass(index) {
      return index === this.step ? 'in' : ''
    },
    next() {
      this.isEnd && callFn(this.reset)

      if (this.isEnd || this.isNextStepBlockedOrHidden) {
        return
      }

      this.step = this.nextStep
    },
    hasBackButton(index) {
      const step = index - 1

      return this.step === index && this.backButtonList[step]
    },
  },
}
</script>
